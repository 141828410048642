export const palette = {
  dark: 'dark',
  light: 'light',
}

export const setTheme = (text, bg) => {
  document.documentElement.style.setProperty('--theme-a', text)
  document.documentElement.style.setProperty('--theme-b', bg)
}

export const applyTheme = theme => {
  if (theme === palette.dark) {
    setTheme('white', '#191919')
  } else {
    setTheme('black', 'white')
  }
}
