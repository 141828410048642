import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { applyTheme } from '../utils/theme'

const presentationalUrl = url => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
}

const WorkInfoTemplate = ({ location, data, pageContext }) => {
  const { data: pageData } = data.prismicWork
  const { section, uid } = pageContext

  const [fromWorkItem, setFromWorkItem] = useState(false)

  useEffect(() => {
    applyTheme(section === 'archive' ? 'dark' : 'light')
  }, [section])

  useEffect(() => {
    if (location.state && location.state.fromWorkItem) {
      setFromWorkItem(true)
    }
  }, [location.state])

  return (
    <div className="p-gs">
      <div className="flex items-center justify-between mb-22">
        {pageData.title && <h1 className="text-2xl pt-1">{pageData.title}</h1>}
        <Link
          to={`/${section}/${uid}`}
          state={{ fromWorkInfo: fromWorkItem }}
          className="link flex items-center justify-end w-7.5 h-7.5"
        >
          <div className="w-5 h-5 p-px">
            <svg
              viewBox="0 0 19 19"
              className="block w-full h-full fill-current"
            >
              <path d="M19 1.5L17.5 0l-8 8-8-8L0 1.5l8 8-8 8L1.5 19l8-8 8 8 1.5-1.5-8-8z" />
            </svg>
          </div>
        </Link>
      </div>

      {pageData.types && pageData.website.raw.url && (
        <div className="mb-22 space-y-4 lg:space-y-6">
          <React.Fragment>
            {pageData.types && (
              <div className="text-sm">
                <h4 className="opacity-50">Scope of Work</h4>
                <ul>
                  {pageData.types.map(
                    (item, index) =>
                      item.type.document && (
                        <li key={index}>{item.type.document.data.title}</li>
                      )
                  )}
                </ul>
              </div>
            )}
            {pageData.website.raw.url && (
              <div className="text-sm">
                <h4 className="opacity-50">Website</h4>
                <a
                  className="link"
                  href={pageData.website.raw.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {presentationalUrl(pageData.website.raw.url)}
                </a>
              </div>
            )}
            {pageData.awards.length > 0 && (
              <div className="text-sm">
                <h4 className="opacity-50">Awards</h4>
                {pageData.awards
                  .filter(item => item.award)
                  .map((item, index) => {
                    let awardStyle = {
                      backgroundColor: '#' + item.hex_colour,
                    }
                    return (
                      <div className="flex items-center" key={index}>
                        {item.hex_colour && (
                          <div
                            className="w-3 h-3 rounded-full mr-1"
                            style={awardStyle}
                          ></div>
                        )}
                        <span className="pt-1">{item.award}</span>
                      </div>
                    )
                  })}
              </div>
            )}
          </React.Fragment>
        </div>
      )}

      {pageData.content.html && (
        <div
          className="rte text-md"
          dangerouslySetInnerHTML={{
            __html: pageData.content.html,
          }}
        ></div>
      )}
    </div>
  )
}

WorkInfoTemplate.propTypes = {
  location: PropTypes.object.isRequired,
}

export const workInfoTemplateQuery = graphql`
  query workInfoByUid($uid: String) {
    prismicWork(uid: { eq: $uid }) {
      uid
      data {
        meta_title
        meta_description
        title
        types {
          type {
            document {
              ... on PrismicWorkType {
                data {
                  title
                }
              }
            }
          }
        }
        awards {
          award
          hex_colour
        }
        website {
          raw
        }
        content {
          html
        }
      }
    }
  }
`

export default WorkInfoTemplate
